<template>
  <ul class="list" :class="classes">
    <slot />
  </ul>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'AppList',
  props: {
    truncateText: {
      type: Boolean,
      default: false,
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        'list--truncate-text': props.truncateText,
        'list--border-top': props.borderTop,
      }
    })

    return {
      classes,
    }
  },
}
</script>

<style scoped>
.list {
  /* offset AppListItemContent padding */
  @apply -mt-4;
  @screen sm {
    @apply mt-0;
  }
  &.list--truncate-text {
    /* targets AppListItem component and its children   */
    ::v-deep .list-item-left,
    ::v-deep .list-item-center,
    ::v-deep .list-item-right {
      @apply truncate;

      & > * {
        @apply truncate;
      }
    }
  }
  &.list--border-top {
    @apply border-t border-gray-lighter mt-0;
  }
}
</style>
