<template>
  <form class="documents">
    <!-- Document selectors -->
    <XeRadioSelectors v-model="selectedOption" class="mb-6" :options="content.options" />

    <!-- Field from document selection -->
    <AppDynamicForm v-if="renderFormFields" class="mt-10" :fields="renderFormFields" />
  </form>
</template>

<script>
import { ref, onBeforeMount, computed, watch } from '@vue/composition-api'

import passportFields from '@/data/pendingActions/businessOwnersDetailsPassport'
import licenceFields from '@/data/pendingActions/businessOwnersDetailsLicence'
import { useAuthorizedPersonnelForm } from '@/forms/AuthorizedPersonnelForm'

import XeRadioSelectors from '@/components/XeRadioSelectors/XeRadioSelectors'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'

import { getFormData } from '@/utils/form'
import { getRegionFromCountry } from '@/utils/i18n'
import { useDashboardStore } from '@/stores/dashboard'
import {
  LICENSE_KEYS,
  PASSPORT_KEYS,
  ALLOWED_REGIONS,
  FIELDS_TO_IGNORE,
  EVENTS,
  ACTIONS,
} from '@/constants/businessOwnersDetailsDocuments'

export default {
  components: {
    XeRadioSelectors,
    AppDynamicForm,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    country: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const dashboardStore = useDashboardStore()

    // Default values
    const selectedOption = ref('')
    const getAuthorizedPersonnelList = computed(() => dashboardStore.authorizedPersonnel)

    /* FORM */

    // form factory
    const passportFormFactory = useAuthorizedPersonnelForm()
    const driverLicenceFormFactory = useAuthorizedPersonnelForm()

    // Get field utils from composable
    const {
      setup: passportSetup,
      formFields: passportFormFields,
      hasFormFields: hasPassportField,
      isFormInvalid: isPassportInvalid,
    } = passportFormFactory
    const {
      setup: licenceSetup,
      formFields: licenceFormFields,
      hasFormFields: hasLicenceField,
      isFormInvalid: isLicenceInvalid,
    } = driverLicenceFormFactory

    // Set form fields programmatically based on radio selector values
    const renderFormFields = computed(() => {
      if (selectedOption.value) {
        return selectedOption.value === LICENSE_KEYS.DRIVER_LICENSE
          ? licenceFormFields.value
          : passportFormFields.value
      }
    })

    const createFields = () => {
      passportSetup(filterFormFields(PASSPORT_KEYS.SELECTION_ID, passportFields))
      licenceSetup(filterFormFields(LICENSE_KEYS.SELECTION_ID, licenceFields))
    }

    /* FILTERS */
    const region = getRegionFromCountry(props.country)
    const isAllowedRegion = ALLOWED_REGIONS.includes(region)

    // Return ignore list based on selected form id
    const fieldsToIgnore = (formId) => {
      return formId === LICENSE_KEYS.SELECTION_ID
        ? FIELDS_TO_IGNORE.DRIVER_LICENSE[region]
        : FIELDS_TO_IGNORE.PASSPORT[region]
    }
    // Return an array of non-ignored fields based on the ignored list
    const removeIgnoreFields = (formId, formFields) => {
      return formFields.fields.filter(({ id }) => !fieldsToIgnore(formId).includes(id))
    }
    // Set form formatted form fields using
    const filterFormFields = (formType, formFields) => {
      if (isAllowedRegion) {
        return {
          ...formFields,
          fields: removeIgnoreFields(formType, formFields),
        }
      }
      return formFields
    }

    /*  DATA */

    // Set a data object with fields from the dynamic form.
    const licenceData = computed(() => getFormData(licenceFormFields.value))
    const passportData = computed(() => getFormData(passportFormFields.value))

    // Get data from licence or passport fields
    const dataToEmit = computed(() => {
      let docDetails = {}
      if (selectedOption.value) {
        docDetails[selectedOption.value] =
          selectedOption.value === LICENSE_KEYS.DRIVER_LICENSE
            ? licenceData.value
            : passportData.value
      }
      return docDetails
    })
    // Listening for changes in data to emit the variable
    watch(dataToEmit, (items) => emit(EVENTS.documentsData, items))

    /* VALIDATION */

    // Licence
    const licenceValidation = computed(() =>
      Boolean(!hasLicenceField.value || isLicenceInvalid.value)
    )

    // Passport
    const passportValidation = computed(() =>
      Boolean(!hasPassportField.value || isPassportInvalid.value)
    )

    // Validation checker
    const buttonDisabled = computed(() => {
      if (selectedOption.value) {
        return selectedOption.value === LICENSE_KEYS.DRIVER_LICENSE
          ? licenceValidation.value
          : passportValidation.value
      } else {
        return true
      }
    })
    watch(
      () => buttonDisabled,
      (status) => emit(EVENTS.documentsValidation, !status.value),
      { deep: true }
    )

    /* EDIT */

    // Get save data
    const getSavedData = () => {
      if (getAuthorizedPersonnelList.value.length > 0) {
        const selectedItem = getAuthorizedPersonnelList.value[props.index]
        // Set radio button default value
        selectedOption.value = Object.keys(selectedItem.docdetails).toString()
        // Set save data into form fields
        preserveData(selectedItem)
      }
    }

    const setIssuingCountry = () => {
      passportFormFields.value.forEach((field) => {
        if (field.id === PASSPORT_KEYS.ISSUING_COUNTRY) {
          field.value = props.country
        }
      })
    }

    const preserveData = (item) => {
      const form =
        selectedOption.value === LICENSE_KEYS.ID
          ? licenceFormFields.value
          : passportFormFields.value
      form.forEach((e) => (e.value = item.docdetails[selectedOption.value][e.id]))
    }

    onBeforeMount(async () => {
      // Create fields to render
      createFields()
      // Set country on issuing data
      await setIssuingCountry()
      // Set save data into form fields
      if (props.action === ACTIONS.EDIT) {
        getSavedData()
      }
    })

    return {
      selectedOption,
      renderFormFields,
      buttonDisabled,
      passportValidation,
      licenceValidation,
      licenceData,
      passportData,
      fieldsToIgnore,
    }
  },
}
</script>
