<template>
  <div class="list-item-caption">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppListItemCaption',
  emits: [],
  props: {},
}
</script>

<style lang="postcss" scoped>
.list-item-caption {
  @apply type-caption text-secondary-text block;
}
</style>
