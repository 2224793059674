<template>
  <figure class="figure" :class="figureTheme">
    <!-- @slot default: Icon or text here -->
    <slot />
  </figure>
</template>

<script>
import { computed } from '@vue/composition-api'
export const themes = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'gray',
]
export default {
  name: 'AppFigure',
  components: {},
  props: {
    theme: {
      type: String,
      default: 'gray',
      validation: (value) => {
        return themes.indexOf(value) > -1
      },
    },
  },
  setup(props) {
    const figureTheme = computed(() => {
      return `figure--${props.theme}`
    })
    return {
      figureTheme,
    }
  },
}
</script>

<style scoped>
.figure {
  @apply type-body font-bold rounded-xl inline-flex items-center justify-center;
  @apply w-14 h-14;
}
.figure--red {
  @apply text-red-text bg-red-lighter;
}
.figure--orange {
  @apply text-orange-text bg-orange-lighter;
}
.figure--yellow {
  @apply text-yellow-text bg-yellow-lighter;
}
.figure--green {
  @apply text-green-text bg-green-lighter;
}
.figure--blue {
  @apply text-blue-text bg-blue-lighter;
}
.figure--purple {
  @apply text-purple-text bg-purple-lighter;
}
.figure--pink {
  @apply text-pink-text bg-pink-lighter;
}
.figure--gray {
  @apply text-secondary-text bg-gray-lightest border border-gray-lighter;
}
</style>
