<template>
  <div class="w-full">
    <!-- Outer title -->
    <!-- Button -->
    <div
      v-if="mq.current !== 'xs' && suggestedIndividuals.length > 0 && suggestedStepCompleted"
      class="back-button"
    >
      <AppButton class="-ml-1" theme="icon" @click="backButtonClick">
        <AppIcon name="Go Back">
          <IconArrowLeft />
        </AppIcon>
      </AppButton>
    </div>
    <XeStpHeading v-if="mq.current !== 'xs'" :heading="formatPageHeadlines.headline" is-outer />
    <AppCard :size="'1/2xl'">
      <form>
        <!-- Suggested individuals -->
        <div v-if="hasSuggestedIndividuals" class="mt-8">
          <SuggestedIndividuals
            :suggested-individuals="suggestedIndividuals"
            @individuals-selected="suggestedIndividualsSelected($event)"
          />
        </div>
        <!-- Business owners list -->
        <div v-else class="mt-6 sm:mt-0">
          <!-- Sub-headline -->
          <h4 class="type-stp-subheadline" v-html="formatPageHeadlines.subHeadline"></h4>

          <!-- List of owners -->
          <BusinessOwnersList
            :items="authorizedPersonnel"
            class="pt-8"
            @editOwner="
              ({ action, person, index }) => goToBusinessOwnersDetails(action, person, index)
            "
            @onDeleteTriggered="onDeleteTriggered($event)"
            @createOwner="goToBusinessOwnersDetails($event)"
          />
          <!-- Footer -->
          <AppCardFooter class="business-owners-footer justify-end">
            <AppButton :disabled="isSubmitButtonDisabled" @click.prevent="toggleShowConfirmModal">
              Save & Continue
            </AppButton>
          </AppCardFooter>
        </div>
      </form>
    </AppCard>

    <!-- Delete modal -->
    <AppModal
      v-if="showDeleteModal"
      :value="showDeleteModal"
      :dismissible="true"
      :content-centered="true"
      :persistent="true"
      class="business-owner-modal"
    >
      <template>
        <div class="close-icon-container">
          <span class="close-icon" @click="resetDeleteTrigger">
            <AppIcon name="Close">
              <IconClose />
            </AppIcon>
          </span>
        </div>
        <div class="modal-body-container">
          <template>
            <div class="warning-icon-container">
              <img
                :src="require('../../../../public/img/icons/warning.svg')"
                alt="Warning sign"
                class="warning-icon"
              />
            </div>
          </template>
          <h1 class="modal-heading">Are you sure you want to delete this person?</h1>
          <p class="modal-subheading">
            Their information will be permanently removed from your account
          </p>
        </div>
        <div class="flex flex-col">
          <AppButton class="w-full" :loading="loading" @click="deleteBusinessOwner">
            Continue
          </AppButton>

          <AppButton class="w-full mt-6" theme="text" @click="resetDeleteTrigger">
            Cancel
          </AppButton>
        </div>
      </template>
    </AppModal>

    <!-- Confirm Submission modal -->
    <AppModal
      v-if="showConfirmOwnersModal"
      :value="showConfirmOwnersModal"
      :dismissible="true"
      :content-centered="true"
      :persistent="true"
      class="confirm-owner-modal"
    >
      <template>
        <div class="modal-body-container">
          <div class="modal-heading">Confirm owners and controllers</div>
        </div>
        <div class="agree-all-owners-checkbox">
          <AppInputCheckbox v-model="agreeAllOwnersCheckbox" />
          <p class="agree-all-owners-text">
            {{ formatConfirmationCheckboxMessage(corpProfile, isDirectorNeeded) }}
          </p>
        </div>
        <div class="confirm-owners-footer">
          <AppButton class="w-full mt-6" theme="text" @click="toggleShowConfirmModal">
            Cancel
          </AppButton>

          <AppButton
            class="w-full"
            :loading="loading"
            :disabled="isModalSubmitButtonDisabled"
            @click="goToPendingActions"
          >
            Confirm
          </AppButton>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'

// Store
import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'

// Components
import AppIcon from '@/components/AppIcon/AppIcon'
import AppCard from '@/components/AppCard/AppCard'
import AppButton from '@/components/AppButton/AppButton'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox.vue'
import AppModal from '@/components/AppModal/AppModal'
import XeStpHeading from '@/components/XeStpHeading/XeStpHeading.vue'
import BusinessOwnersList from './BusinessOwnersList.vue'
import SuggestedIndividuals from './SuggestedIndividuals'

// Icons
import { IconClose, IconArrowLeft } from '@moneytransfer.ui/euronet-icons'

// Composables
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'

// Helpers
import {
  isEveryIndividualsValid,
  suggestedIndividualList,
  formatConfirmationCheckboxMessage,
} from '@/helpers/businessOwners'

// Utils
import { isSamePerson } from '@/utils/array/index.js'
import { namesToArrayLowerCase } from '@/utils/string/index.js'

// Constants
import eventMapper from '@/constants/events'
import { storeToRefs } from 'pinia'

export default {
  components: {
    IconClose,
    IconArrowLeft,
    AppIcon,
    AppCard,
    AppButton,
    AppCardFooter,
    AppInputCheckbox,
    AppModal,
    SuggestedIndividuals,
    XeStpHeading,
    BusinessOwnersList,
  },
  setup() {
    const mq = useMediaQuery()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const { getAuthorizedPersonnelFromBvd, suggestedBvdPersonnelResult } =
      storeToRefs(dashboardStore)

    const router = useRouter()

    // Default variables
    const authorizedPersonnel = ref([])
    const showDeleteModal = ref(false)
    const showConfirmOwnersModal = ref(false)
    const indexToBeDeleted = ref(null)
    const storedAuthorizedPersonnel = computed(() => dashboardStore.authorizedPersonnel)
    const isDirectorNeeded = dashboardStore.getNeedsDirector
    const companyType = dashboardStore.companyDetails['companytype']?.toLowerCase()
    const agreeAllOwnersCheckbox = ref(false)
    const corpProfile = dashboardStore.corpProfile

    const backButtonClick = () => {
      suggestedStepCompleted.value = false
      dashboardStore.businessOwnersOptions = { suggestedStepCompleted: false }
    }

    // Content
    const suggestedStepCompleted = ref(false)
    const suggestedIndividualsSelected = (selectedIndividuals) => {
      if (selectedIndividuals) {
        selectedIndividuals.forEach((individual) => {
          dashboardStore.setAuthorizedPersonnel(individual)
        })
      }
      suggestedStepCompleted.value = true
      dashboardStore.businessOwnersOptions = { suggestedStepCompleted: true }
    }
    const businessOwnersOptions = computed(() => dashboardStore.businessOwnersOptions)

    // listening mobile navigation back button
    watch(
      businessOwnersOptions,
      (value) => (suggestedStepCompleted.value = value.suggestedStepCompleted),
      {
        deep: true,
      }
    )
    const suggestedIndividuals = computed(() =>
      suggestedIndividualList(suggestedBvdPersonnelResult.value, authorizedPersonnel.value)
    )

    const hasSuggestedIndividuals = computed(
      () => suggestedIndividuals.value.length > 0 && !suggestedStepCompleted.value
    )
    const hideBackButton = computed(
      () => suggestedIndividuals.value.length > 0 && suggestedStepCompleted.value
    )
    watch(hideBackButton, (status) => {
      dashboardStore.businessOwnersOptions = {
        suggestedStepCompleted: suggestedStepCompleted.value,
        hideBackButton: suggestedIndividuals.value.length > 0 ? true : status,
      }
    })

    const goToBusinessOwnersDetails = (action, person, index) => {
      if (action === 'create') {
        // fire add individual event if action is create
        analyticsStore.track({
          event: eventMapper.BUSINESS_OWNERS.addIndividual,
        })
      }

      const params = { action, authorizedPersonnel: person, index }
      router.push({
        name: 'BusinessOwnersDetails',
        params,
      })
    }

    onMounted(() => {
      // FIRST TIME LOADING (empty store)
      if (storedAuthorizedPersonnel.value.length === 0) {
        // Check bvd suggestions
        if (
          getAuthorizedPersonnelFromBvd.value.length > 0 &&
          suggestedBvdPersonnelResult.value.length === 0
        ) {
          // Create a copy of the list from BVD to use and make changes if needed
          dashboardStore.copySuggestedIndListFromBvd()
        }
        // FIRST LOAD
        // 1) GET THE LOGGED IN USER
        // 2) COMPARE USER TO SUGGESTED PEOPLE
        // 3) MERGE ROLES (IF MATCH)
        // 4) REMOVE SUGGESTED AND MATCHED PPL
        // 5) ADD TO STORE AUTH-SIGN + EXTRA ROLES
        // 6) SET NAV OPTIONS

        // get authorized signatory from mainContact & BVD on first load
        let authorizedSignatory = dashboardStore.getAuthorizedSignatory
        const authNames = namesToArrayLowerCase(
          authorizedSignatory?.firstName || '',
          authorizedSignatory?.lastName || ''
        )
        const indexesToRemoveFromBvd = []
        const matchedPeopleFromBvd = suggestedBvdPersonnelResult.value.filter(
          (eachBvdPerson, index) => {
            const bvdNames = namesToArrayLowerCase(
              eachBvdPerson?.firstName || '',
              eachBvdPerson?.lastName || ''
            )
            if (isSamePerson(authNames, bvdNames)) {
              // adding in reverse order
              indexesToRemoveFromBvd.unshift(index)
              dashboardStore.hasSuggestedIndividualsFinished = false
              return true
            }
            return false
          }
        )
        // Remove matched people from BVD list
        indexesToRemoveFromBvd.forEach((ind) => {
          suggestedBvdPersonnelResult.value.splice(ind, 1)
        })
        // Merge data from BVD
        matchedPeopleFromBvd.forEach((person) => {
          delete person.firstName
          delete person.lastName
          if (authorizedSignatory?.personneltype && person?.personneltype) {
            authorizedSignatory.personneltype = [
              ...authorizedSignatory.personneltype,
              ...person.personneltype,
            ]
            delete person.personneltype
          }
          authorizedSignatory = {
            ...authorizedSignatory,
            ...person,
          }
        })
        dashboardStore.setAuthorizedPersonnel(authorizedSignatory)
        dashboardStore.businessOwnersOptions = {
          suggestedStepCompleted: false,
          hideBackButton: true,
          ownerName: null,
        }
      } else {
        // NOT FIRST LOAD (page reload or something, there's info on the stores)
        authorizedPersonnel.value = [...storedAuthorizedPersonnel.value]
        suggestedStepCompleted.value = dashboardStore.hasSuggestedIndividualsFinished
        // Enable back button
        if (!dashboardStore.hasSuggestedIndividualsFinished) {
          dashboardStore.businessOwnersOptions = {
            suggestedStepCompleted: false,
            hideBackButton: true,
            ownerName: null,
          }
        }
      }
    })

    watch(
      storedAuthorizedPersonnel,
      (data) => {
        if (data?.length && data.length > 0) {
          authorizedPersonnel.value = [...data]
        } else {
          authorizedPersonnel.value = []
        }
      },
      { deep: true }
    )

    // Sync stepper active item with content
    const formatPageHeadlines = computed(() => {
      // design up to date with figma
      // https://www.figma.com/file/LyY3cdsjzz0iow86DVuWvX/%5BXe%5D-Activation---Prioritized-Initiatives?type=design&node-id=23379-104491

      let msg = ''
      if (suggestedBvdPersonnelResult.value.length === 0) {
        // if nothing from BVD, return immediately
        msg = '(25%+ ownership).'
      } else if (companyType?.includes('sole')) {
        // if ST, return immediately
        msg = '(more than 25% of ownership).'
      } else {
        let as = storedAuthorizedPersonnel.value.find((ap) => ap.isSignatory)

        if (as?.isDirector) {
          // AS not director
          msg =
            '(more than 25% of ownership) and at least one director or equivalent in your business.'
        } else {
          // AS is director
          msg = '(25%+ ownership) and at least <b>one director</b> or equivalent in your business.'
        }
      }
      let subHeadline = `We must verify the identity of all beneficial owners ${msg}`

      return {
        id: 'default',
        headline: 'Owners and controllers',
        subHeadline,
      }
    })

    const resetDeleteTrigger = () => {
      showDeleteModal.value = false
      indexToBeDeleted.value = null
    }
    const onDeleteTriggered = (index) => {
      showDeleteModal.value = true
      indexToBeDeleted.value = index
    }

    const deleteBusinessOwner = () => {
      if (indexToBeDeleted.value !== null) {
        dashboardStore.deleteAuthorizedPersonnelByIndex({
          index: indexToBeDeleted.value,
        })
        analyticsStore.track({
          event: eventMapper.BUSINESS_OWNERS.deleteIndividual,
        })
      }
      resetDeleteTrigger()
    }

    const isSubmitButtonDisabled = computed(() => {
      return !(
        dashboardStore.getIsDirectorAdded && isEveryIndividualsValid(authorizedPersonnel.value)
      )
    })

    const isModalSubmitButtonDisabled = computed(
      () => isSubmitButtonDisabled.value || !agreeAllOwnersCheckbox.value
    )

    const loading = ref(false)

    const goToPendingActions = async () => {
      loading.value = true
      await dashboardStore.putCorpProfile()
      loading.value = false
      router.push({ name: 'PendingActions' })
    }

    const toggleShowConfirmModal = () => {
      agreeAllOwnersCheckbox.value = false
      showConfirmOwnersModal.value = !showConfirmOwnersModal.value
    }

    return {
      mq,
      formatPageHeadlines,
      authorizedPersonnel,
      suggestedIndividuals,
      goToBusinessOwnersDetails,
      isSubmitButtonDisabled,
      showDeleteModal,
      loading,
      onDeleteTriggered,
      deleteBusinessOwner,
      resetDeleteTrigger,
      goToPendingActions,
      showConfirmOwnersModal,
      toggleShowConfirmModal,
      formatConfirmationCheckboxMessage,
      agreeAllOwnersCheckbox,
      isModalSubmitButtonDisabled,
      isDirectorNeeded,
      corpProfile,
      suggestedStepCompleted,
      suggestedIndividualsSelected,
      backButtonClick,
      hasSuggestedIndividuals,
      hideBackButton,
    }
  },
}
</script>

<style lang="postcss" scoped>
.back-button {
  @apply px-4;
  @screen sm {
    @apply px-0;
  }
  @screen md {
    @apply max-w-1/2xl ml-auto mr-auto;
  }
}
.business-owners-actions {
  @apply flex gap-4;
}
.type-stp-title {
  @apply max-w-1/2xl m-auto pb-3 text-xl pl-4 font-semibold pt-6;
  @screen sm {
    @apply text-3xl font-semibold pl-0 pb-12 pt-0;
  }
  @screen md {
    @apply text-3xl;
  }
}
.type-stp-subheadline {
  @apply type-caption text-gray leading-6;
  font-size: 15px;
}
.card {
  /deep/ .card-content {
    @apply pt-0 !important;

    @screen sm {
      @apply pt-12 !important;
    }

    .card-content-block {
      @apply mb-8 !important;
      @screen sm {
        @apply mb-0 !important;
      }
    }
  }
}

.suggested-individuals-wrapper {
  @apply pt-12;
}
.add-individual {
  @apply pt-5;
  border-bottom: 1px solid #f3f4f6;

  .add-individual-button {
    @apply pb-4 pt-4 text-left !important;
    ::v-deep .button-inner {
      @apply type-caption-bold p-0 pr-2 rounded-2xl !important;
    }
    &-icon {
      @apply rounded-2xl h-auto w-auto p-4 mr-4;
      background-color: #f2f7fe;
    }
  }
}
.business-owners-footer {
  @apply pt-0 z-50 !important;
  @screen sm {
    @apply pt-10 !important;
  }
}

.business-owner-modal {
  /deep/ .overlay-content {
    .card {
      max-width: 536px;
    }
  }
  >>> .card {
    @media only screen and (max-width: 600px) {
      height: 100vh !important;
      width: 100vw;

      .card-content {
        @apply py-6 !important;
      }

      .card-content-block {
        @apply flex flex-col h-full justify-between;
      }
    }
  }

  .close-icon-container {
    @apply text-right pb-4;
    .close-icon {
      @apply cursor-pointer inline-block;
    }
  }
  .modal-body-container {
    @apply text-center pb-6;
    .warning-icon-container {
      @apply pb-8 flex justify-center;
      .warning-icon {
        height: 88px;
      }
    }
    .modal-heading {
      @apply mb-1 type-h2 leading-10 text-2xl font-semibold;

      @screen sm {
        @apply mb-1 type-h1 leading-10 text-3xl font-semibold;
      }
    }
    .modal-subheading {
      @apply text-text-gray-primary type-subtitle text-sm;
    }
  }
}

.confirm-owner-modal {
  /* Setting the max width for the card */

  /deep/ .overlay-content {
    .card {
      max-width: 640px;
    }
  }

  /* Modal base card */
  >>> .card {
    /* tablet screen */
    @media only screen and (max-width: 744px) {
    }

    /* mobile screen */
    .card-content {
      @apply py-12 !important;
    }
    @media only screen and (max-width: 640px) {
      height: 100vh !important;
      width: 100vw;

      .card-content {
        @apply py-6 !important;
      }

      .card-content-block {
        @apply flex flex-col h-full justify-between;
      }
    }
  }

  .modal-body-container {
    @apply text-center pb-6;
    @media only screen and (max-width: 640px) {
      @apply text-center pb-3;
    }
    .warning-icon-container {
      @apply pb-8 flex justify-center;
      .warning-icon {
        height: 88px;
      }
    }
    .modal-heading {
      @apply mb-1 type-h2 leading-10 text-xl font-semibold;

      @screen sm {
        @apply mb-1 type-h1 leading-10 text-3xl font-semibold;
      }
    }
    .modal-subheading {
      @apply text-text-gray-primary type-subtitle text-sm;
    }
  }
  .agree-all-owners-checkbox {
    @apply flex justify-items-center items-center p-6 border border-gray-light rounded-2xl shadow-ria-1;
    .agree-all-owners-text {
      @apply type-caption text-gray-primary pl-4;
    }
  }
  .confirm-owners-footer {
    @apply pt-0 z-50 !important;

    @screen sm {
      @apply pt-10 !important;
    }

    @media only screen and (max-width: 640px) {
      @apply flex flex-col-reverse justify-start !important;
      flex-grow: 1;
    }

    /* Add the text-right utility class */
    @apply text-right;
  }
}

.list-item-title {
  @apply type-body-bold font-medium !important;
}
</style>
