const BUSINESS_OWNERS_DETAILS_DOCUMENTS = {
  documentsValidation: 'documentsValidation',
  documentsData: 'documentsData',
}

const BUSINESS_OWNERS = {
  addIndividual: 'Add Individual',
  deleteIndividual: 'Individual Deleted',
}

const eventMapper = {
  BUSINESS_OWNERS_DETAILS_DOCUMENTS,
  BUSINESS_OWNERS,
}

export default eventMapper
