import { render, staticRenderFns } from "./DashboardBusinessOwners.vue?vue&type=template&id=53264904&scoped=true&"
import script from "./DashboardBusinessOwners.vue?vue&type=script&lang=js&"
export * from "./DashboardBusinessOwners.vue?vue&type=script&lang=js&"
import style0 from "./DashboardBusinessOwners.vue?vue&type=style&index=0&id=53264904&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53264904",
  null
  
)

export default component.exports