import eventMapper from './events'

export const OPTIONS = {
  ACTIONS,
  EVENTS,
  FILTERS,
  KEYS,
}

export const FILTERS = {
  ALLOWED_REGIONS,
  FIELDS_TO_IGNORE,
}

export const KEYS = {
  LICENSE_KEYS,
  PASSPORT_KEYS,
}

export const EVENTS = eventMapper.BUSINESS_OWNERS_DETAILS_DOCUMENTS

export const ACTIONS = {
  EDIT: 'edit',
}

// KEYS
export const LICENSE_KEYS = {
  ID: 'drivinglicence',
  DRIVER_LICENSE: 'drivinglicence',
  SELECTION_ID: 'drivinglicence',
  LICENSE_NUMBER: 'licenceNumber',
  VERSION_NUMBER: 'versionNumber',
  CARD_NUMBER: 'cardNumber',
  ISSUING_STATE: 'issuingState',
  EXPIRY_DATE: 'expiryDate',
}

export const PASSPORT_KEYS = {
  ID: 'passport',
  SELECTION_ID: 'passport',
  ISSUING_COUNTRY: 'issuingCountry',
  PASSPORT_NUMBER: 'passportNumber',
  EXPIRY_DATE: 'expiryDate',
}

// FILTERS
export const ALLOWED_REGIONS = ['NZ', 'AU']

export const FIELDS_TO_IGNORE = {
  DRIVER_LICENSE: {
    AU: [LICENSE_KEYS.VERSION_NUMBER],
    NZ: [LICENSE_KEYS.ISSUING_STATE, LICENSE_KEYS.CARD_NUMBER],
  },
  PASSPORT: {
    AU: [],
    NZ: [],
  },
}
