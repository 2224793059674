<template>
  <div>
    <!-- NEW WAY -->
    <div>
      <h1 class="inner-card-header">Are these individuals part of your company?</h1>
      <AppList>
        <div class="flex justify-end sm:border-b mt-4">
          <AppButton theme="text" class="add-individual-button" @click="toggleAllCheckboxes">
            {{ toggleAllCheckboxesText }}
          </AppButton>
        </div>
        <AppListItem v-for="(individual, index) in individuals" :key="index">
          <template #left>
            <div class="flex justify-between">
              <div>
                <AppListItemTitle class="xe-item-title">
                  {{ formatFullName(individual) }}
                </AppListItemTitle>
                <AppListItemCaption class="xe-item-caption">
                  {{ formatRole(individual) }}
                </AppListItemCaption>
              </div>
              <div class="self-center">
                <AppInputCheckbox
                  :value="individual.isSelected"
                  class="mr-3"
                  style="display: block !important"
                  @input="toggleIndividual(individual)"
                />
              </div>
            </div>
          </template>
        </AppListItem>
      </AppList>
      <!-- Mobile -->
      <div class="flex justify-center">
        <AppButton theme="text" class="sm:hidden" @click="clickNoneOfAbove">
          None of the above
        </AppButton>
      </div>
      <AppCardFooter class="business-owners-footer justify-end">
        <div class="w-full sm:w-max-content">
          <AppButton theme="text" class="hidden sm:inline-block md:mr-5" @click="clickNoneOfAbove">
            None of the above
          </AppButton>
          <AppButton class="w-full md:ml-5" :disabled="!someSelected" @click="submitSelected"
            >Confirm</AppButton
          >
        </div>
      </AppCardFooter>
    </div>
  </div>
</template>

<script>
// Components
import AppList from '@/components/AppList/AppList'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppListItem from '@/components/AppListItem/AppListItem'
import AppListItemTitle from '@/components/AppListItemTitle/AppListItemTitle'
import AppListItemCaption from '@/components/AppListItemCaption/AppListItemCaption'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox'
import AppButton from '@/components/AppButton/AppButton'

// Composables
import { useDashboardStore } from '@/stores/dashboard'

import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    AppCardFooter,
    AppButton,
    AppList,
    AppListItem,
    AppListItemTitle,
    AppListItemCaption,
    AppInputCheckbox,
  },
  props: {
    suggestedIndividuals: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const individuals = ref([])
    const dashboardStore = useDashboardStore()

    const formatFullName = ({ firstName, lastName }) => {
      const capitalizeWords = (phrase) =>
        phrase
          .split(' ')
          .filter((word) => word) // Filters out empty strings
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')

      return `${capitalizeWords(firstName || '')} ${capitalizeWords(lastName || '')}`.trim()
    }

    const formatRole = ({ isUbo, isDirector }) => {
      if (isUbo && isDirector) {
        return 'Business Owner and Director'
      }
      if (isUbo) {
        return 'Business Owner\u00A0\u00A0\u00A0'
      }
      if (isDirector) {
        return 'Director'
      }
    }

    const addIndividual = (individual) => dashboardStore.setAuthorizedPersonnel(individual)

    const formatDetails = ({ isUbo, isDirector }) => {
      return `${formatRole({ isUbo, isDirector })}`
    }

    const toggleIndividual = (individual) => {
      individual.isSelected = !individual.isSelected
    }
    const toggleAllCheckboxes = () => {
      const newValue = !allSelected.value
      individuals.value.forEach((ind) => (ind.isSelected = newValue))
    }
    const allSelected = computed(() => individuals.value.every((ind) => ind.isSelected))
    const someSelected = computed(() => individuals.value.some((ind) => ind.isSelected))
    const toggleAllCheckboxesText = computed(() =>
      allSelected.value ? 'Deselect all' : 'Select all'
    )

    const submitSelected = () => {
      const selectedIndividuals = individuals.value.filter((individual) => individual.isSelected)
      dashboardStore.hasSuggestedIndividualsFinished = true
      emit('individuals-selected', selectedIndividuals)
    }

    const clickNoneOfAbove = () => {
      dashboardStore.hasSuggestedIndividualsFinished = true
      emit('individuals-selected')
    }

    onMounted(() => {
      individuals.value = props.suggestedIndividuals.map((ind) => {
        return JSON.parse(
          JSON.stringify({
            ...ind,
            isSelected: false,
          })
        )
      })
    })

    return {
      formatFullName,
      formatRole,
      addIndividual,
      formatDetails,
      individuals,
      toggleIndividual,
      toggleAllCheckboxes,
      allSelected,
      someSelected,
      toggleAllCheckboxesText,
      submitSelected,
      clickNoneOfAbove,
    }
  },
}
</script>

<style lang="postcss" scoped>
.inner-card-header {
  @apply mb-1 type-h3 leading-8 text-xl font-semibold;

  @screen sm {
    @apply mb-1 type-h2 leading-8 text-2xl font-semibold;
  }
}
.business-owners-footer {
  @apply pt-0 z-50 !important;
  @screen sm {
    @apply pt-10 !important;
  }
}
.suggested-individuals {
  @apply px-8 py-6 rounded-lg;
  background: #f7f8f9;
  color: #313f5b;

  &-title {
    @apply type-stp-title;
    font-size: 22px;
  }

  .individual {
    @apply pt-6 flex justify-between items-center;

    &-wrapper {
      @apply overflow-hidden;
      flex-grow: 1;
    }

    &-name {
      @apply text-base font-semibold;
      line-height: 22px;
    }

    &-details {
      @apply text-xs font-semibold leading-4 truncate;
      color: #636e82;
      max-width: 80%;
    }

    &-add {
      @apply rounded-lg border flex items-center font-semibold leading-5 gap-2 py-1 px-3;
      font-size: 14px;
      color: #636e82;
      border-color: #636e82;
    }
  }
}
</style>
