export const getFieldValue = (id, formFields) => {
  const field = formFields.value.find((field) => field.id === id)
  if (field && field.value) {
    return field.value
  }
  return ''
}

export const getFormData = (formFields) => {
  let formResult = {}
  formFields.forEach((e) => (formResult[e.id] = e.value || ''))
  return formResult
}
