<template>
  <div class="xe-radio-selectors" role="radiogroup">
    <label
      v-for="(option, index) in options"
      :key="index"
      :for="option.value"
      class="xe-radio-button"
      :class="option.value === selectedItem ? 'xe-radio-selected-item' : null"
      role="radio"
      tabindex="0"
      :aria-checked="option.value == value"
      :aria-disabled="option.disabled"
      @keydown="onKeyPress($event, option.value)"
    >
      <AppRipple class="halo" is-centered :is-disabled="option.disabled">
        <div class="xe-radio-content">
          <!-- Hidden by default -->
          <input
            :id="option.value"
            ref="radio-selector"
            v-model="selectedItem"
            class="xe-radio-input"
            type="radio"
            :name="name"
            :value="option.value"
            :disabled="option.disabled"
            @change="$emit('input', option.value)"
          />
          <!-- Icon -->
          <span class="xe-radio-icon-container">
            <AppIcon class="xe-radio-icon" name="option.label">
              <Component :is="option.icon" class="xe-radio-icon-path" />
            </AppIcon>
          </span>

          <!-- Label -->
          <span class="xe-radio-label">{{ option.label }}</span>
        </div>
      </AppRipple>
    </label>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'

import AppIcon from '@/components/AppIcon/AppIcon'
import IconIdentificationBadge from '@/components/Icon/IconIdentificationBadge'
import IconIdentificationCard from '@/components/Icon/IconIdentificationCard'

export default {
  components: {
    AppIcon,
    IconIdentificationBadge,
    IconIdentificationCard,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedItem = ref('')
    const getDefaultValue = computed(() => props.value)

    watch(getDefaultValue, (v) => selectItem(v))

    const selectItem = (v) => {
      window.document.getElementById(v).click()
      emit('input', v)
    }

    const onKeyPress = (e, v) => {
      if (e.key === ' ' || e.key === 'Enter') {
        selectItem(v)
      }
    }

    return { onKeyPress, selectedItem }
  },
}
</script>

<style lang="postcss" scoped>
.xe-radio {
  &-selectors {
    @apply flex items-center gap-6 self-stretch;
  }

  &-input {
    @apply hidden;
  }

  &-label {
    @apply type-subtitle-bold font-semibold text-gray-blue-400 truncate;
  }

  &-content {
    @apply flex flex-col mx-6 my-4;
  }

  &-icon-container {
    @apply flex justify-center items-center w-10 h-10;
    @apply mb-2 rounded-full bg-gray-300;
    flex-shrink: 0;
  }

  &-icon-path {
    @apply text-gray-blue-200;
  }

  &-button {
    @apply flex flex-col justify-center items-start gap-2;
    @apply border border-gray-400 rounded-xl cursor-pointer;
    flex: 1 0 0;
  }
}

.xe-radio-selected-item {
  @apply border-blue-300 bg-blue-radio;

  .xe-radio-label {
    @apply text-blue-300;
  }

  .xe-radio-icon-container {
    @apply bg-blue-100;
  }

  .xe-radio-icon-path {
    @apply text-blue-300;
  }
}
</style>
