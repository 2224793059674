<template>
  <AppDynamicForm class="manually-address-form" :fields="formFields"></AppDynamicForm>
</template>

<script>
import { useStore } from '@/composables/useStore'
import { useAuthorizedPersonnelForm } from '@/forms/AuthorizedPersonnelForm'
import addressFields from '@/data/templates/addressFields'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import { computed, watch } from '@vue/composition-api'
import { getRegionFromCountry } from '@/utils/i18n'
import { useCountriesStore } from '@/stores/countries'

export default {
  components: { AppDynamicForm },
  props: {
    data: {
      type: Object,
      default: null,
    },
    country: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    // Default values
    const store = useStore()
    const countriesStore = useCountriesStore()
    let selectedAddressField = addressFields[getRegionFromCountry(props.country)]

    // form factory
    const {
      setup: setupFields,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useAuthorizedPersonnelForm(store)
    // Setup custom postcode validation
    selectedAddressField.fields = selectedAddressField.fields.map((field) => {
      if (field.id === 'postCode' && props.country) {
        // Note: Go to region folder to change postCode validation
        field.validation.regex.pattern = countriesStore.getCountryConfigByCountryCode(
          props.country
        ).postalCodeRegex
      }
      return field
    })
    // Set up fields based on address mockup and custom data from parent component
    setupFields(selectedAddressField)
    // Add custom props values
    formFields.value.forEach((field) => {
      // populate data fields
      if (props.data?.freeFormatAddress) {
        const freeFormatAddress = props.data.freeFormatAddress
        field.value = freeFormatAddress[field.id]
      }
      if (props.data?.fixedFormatAddress) {
        const fixedFormatAddress = props.data.fixedFormatAddress
        field.value = fixedFormatAddress[field.id]
      }
      // Set country value
      if (field.id === 'country') {
        field.value = props.country
        field.disabled = true
      }
    })
    // Format address object
    const selectedAddress = computed(() => {
      let addressObject = {}
      formFields.value.forEach((item) => {
        if (item.value) {
          item.id === 'country'
            ? (addressObject['countryCode'] = item.value)
            : (addressObject[item.id] = item.value)
        }
      })
      return addressObject
    })
    watch(selectedAddress, (value) => {
      if (props.data) {
        emit('submit-address', value)
      }
    })
    // Check form validation
    const isSubmitButtonDisabled = computed(() => !hasFormFields.value || isFormInvalid.value)
    watch(
      isSubmitButtonDisabled,
      (value) => {
        emit('has-address-filled', !value)
        if (!value) {
          emit('submit-address', selectedAddress.value)
        }
      },
      { deep: true }
    )

    return {
      formFields,
      isSubmitButtonDisabled,
      hasFormFields,
      isFormInvalid,
      selectedAddress,
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-address-form {
  >>> .county {
    @apply w-full inline-block align-top pr-3;
    max-width: 50%;
  }
  >>> .postal-code {
    @apply w-full inline-block align-top pl-3;
    max-width: 50%;
  }
}
</style>
