<template>
  <div class="mt-8 sm:mt-0">
    <!-- Heading -->
    <XeStpHeading
      v-if="mq.current !== 'xs'"
      data-private
      :heading="heading"
      :sub-heading="subHeading"
      :status="headingStatus"
      is-outer
    />

    <!-- Main content -->
    <AppCard :size="'1/2xl'">
      <!-- Information step - TODO: Make a local component to isolate logic -->
      <form v-if="step === 'addAnIndividual'" class="footer-right">
        <!-- Fields -->
        <template>
          <div class="business-owner-form">
            <AppDynamicForm :fields="formFields"></AppDynamicForm>
          </div>
        </template>

        <!-- Address field - TODO: update existing component to handle manually address -->
        <div>
          <!-- Business -->
          <template v-if="isManuallyClick">
            <ManuallyAddress
              :country="corpProfileCountry"
              :data="manuallyClickData"
              @has-address-filled="onManuallyClickFieldsStatus"
              @submit-address="manuallyAddressData"
            />
          </template>

          <!-- Address Field -->
          <template v-else-if="!isSelectedAddress">
            <!-- Address search -->
            <XeAddressSearch
              data-private
              :dropdown-label="'Personal Address'"
              :show-manual-input-prompt="true"
              :country="corpProfileCountry"
              :is-allow-free-input="true"
              :validation-error-message="addressErrorMessage"
              :value="hasAddress ? '' : formattedAddress"
              @manual="onEnterManuallyClick(true)"
              @input="onInput"
              @select="onSelect"
            />
          </template>

          <!-- Selected Address -->
          <template v-else>
            <div class="p-6 mb-6 border border-solid rounded-2xl border-tertiary-grey-darkest">
              <div class="flex">
                <div class="flex flex-col">
                  <AppIcon name="Location">
                    <IconLocation class="text-main-cyan" />
                  </AppIcon>
                </div>
                <div class="ml-2 flex-grow">
                  <h3 class="type-subtitle-bold">Address</h3>
                  <p class="pt-1 type-caption text-gray-primary" data-private>
                    {{ formattedAddress }}
                  </p>
                </div>
                <div class="flex flex-col justify-center cursor-pointer" @click="onAddressClear">
                  <AppIcon name="Edit">
                    <IconEdit class="text-gray-primary" />
                  </AppIcon>
                </div>
              </div>
            </div>
          </template>
        </div>
      </form>

      <!-- Documents step -->
      <BusinessOwnersDetailsDocuments
        v-if="step === 'documents'"
        :content="selectedContent"
        :country="corpProfileCountry"
        :index="index"
        :action="action"
        @documentsValidation="documentsValidation"
        @documentsData="getDocumentsData"
      />

      <!-- Footer -->
      <AppCardFooter class="business-owners-details-footer">
        <AppButton
          v-if="mq.current !== 'xs'"
          theme="text"
          class="mr-4"
          @click.prevent="backButtonClick"
        >
          Cancel
        </AppButton>
        <AppButton
          :loading="loading"
          :disabled="isSubmitButtonDisabled"
          @click.prevent="submitButtonClick"
        >
          {{ submitButtonText }}
        </AppButton>
      </AppCardFooter>
    </AppCard>
  </div>
</template>

<script>
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'
import { computed, ref, watch } from '@vue/composition-api'

// Store
import { storeToRefs } from 'pinia'
import { useAnalyticsStore } from '@/stores/analytics'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useDashboardStore } from '@/stores/dashboard'
import { useCountriesStore } from '@/stores/countries'

// Components
import AppIcon from '@/components/AppIcon/AppIcon'
import AppCard from '@/components/AppCard/AppCard'
import AppButton from '@/components/AppButton/AppButton'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import XeStpHeading from '@/components/XeStpHeading/XeStpHeading.vue'
import BusinessOwnersDetailsDocuments from './BusinessOwnersDetailsDocuments'
import { IconEdit, IconLocation } from '@moneytransfer.ui/euronet-icons'

// Form
import { useAuthorizedPersonnelForm } from '@/forms/AuthorizedPersonnelForm'

// Data
import mockData from '@/data/pendingActions/businessOwnersDetails'

// Helpers
import { getIndividualRoles, getFullNameOfExistingPersonnel } from '@/helpers/businessOwners'

// Utils
import { isApacRegion, getMappedCountry } from '@/utils/i18n'
import { mapStatesById } from '@/utils/country'
import { getFieldValue } from '@/utils/form'

// Address formatter
import { useAddressFormat } from '@/composables/useAddress'
import XeAddressSearch from '@/components/XeAddressSearch/XeAddressSearch'
import ManuallyAddress from './ManuallyAddress.vue'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'

export default {
  components: {
    IconEdit,
    IconLocation,
    ManuallyAddress,
    AppIcon,
    AppCard,
    AppButton,
    AppCardFooter,
    AppDynamicForm,
    XeAddressSearch,
    XeStpHeading,
    BusinessOwnersDetailsDocuments,
  },
  props: {
    authorizedPersonnel: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    action: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const dashboardStore = useDashboardStore()
    const countriesStore = useCountriesStore()

    const { supportCountryList } = storeToRefs(corporateRegistrationStore)

    // Variables initialization
    const mq = useMediaQuery()
    const loading = ref(false)
    const isManuallyClick = ref(false)
    const manuallyClickData = ref(null)
    const isPep = props.authorizedPersonnel?.isPep
    const isAuthSignatory = !!props.authorizedPersonnel?.isSignatory
    const isCreatingWithData = props.action === 'create' && props.authorizedPersonnel !== null
    const isFirstLoadAuthSignatory = computed(
      () => props.action === 'create' && isAuthSignatory && step.value === 'addAnIndividual'
    )
    const isEditingPersonnel =
      props.action === 'edit' && props.index !== null && props.authorizedPersonnel !== null
    const isApac = computed(() => isApacRegion(corpProfileCountry) === 'APAC')
    const isDocumentValid = ref(false)

    // Profile data
    const corpProfile = dashboardStore.corpProfile
    const corpProfileCountry = corpProfile.registrationAddress.country.toUpperCase()
    const countryConfig = computed(() =>
      countriesStore.getCountryConfigByCountryCode(corpProfileCountry)
    )
    let userFieldsForCountry = mockData[isApacRegion(corpProfileCountry)]

    /* CONTENT */

    // Set page flow
    const pendingActionsConfig = dashboardStore.getStpPageById('BusinessOwnersDetails')
    const contentItems = computed(() => pendingActionsConfig.content.itemsContent)
    // Set the EPAC or default flow
    const contentFlow = computed(() =>
      isApac.value
        ? contentItems.value
        : contentItems.value.filter((item) => item.id === 'addAnIndividual')
    )
    // Sync stepper active item with content
    const step = ref('addAnIndividual')
    const selectedContent = computed(() => contentFlow.value.find((e) => e.id === step.value))

    // Heading
    const headingStatus = computed(() => (isAuthSignatory ? 'Auth Signatory' : ''))
    const heading = computed(() =>
      (isEditingPersonnel || isCreatingWithData) && step.value === 'addAnIndividual'
        ? getFullNameOfExistingPersonnel(props)
        : selectedContent.value.headline
    )
    const subHeading = computed(() =>
      isAuthSignatory
        ? selectedContent.value.authorizedSignatorySubHeadline
        : selectedContent.value.subHeadline
    )

    dashboardStore.businessOwnersOptions = {
      ownerName: heading.value,
      hideBackButton: true,
    }

    // Buttons
    const submitButtonText = computed(() =>
      isApac.value ? selectedContent.value.apacButton : selectedContent.value.submitButton
    )
    const submitButtonClick = () => {
      if (isApac.value) {
        return step.value === 'addAnIndividual' ? (step.value = 'documents') : saveBusinessOwner()
      } else {
        return saveBusinessOwner()
      }
    }
    const backButtonClick = () => {
      if (isApac.value) {
        return step.value === 'documents' ? (step.value = 'addAnIndividual') : goToBusinessOwners()
      } else {
        return goToBusinessOwners()
      }
    }
    // Get country list if not available
    if (supportCountryList.value.length === 0) {
      corporateRegistrationStore.fetchCountryList()
    }

    const stateList = mapStatesById(corporateRegistrationStore.stateList)
    const streetType = corporateRegistrationStore.streetTypeList
    // Get street type and state list
    if (stateList.length === 0 || streetType.length === 0) {
      corporateRegistrationStore.fetchStateList(getMappedCountry(corpProfileCountry))
      corporateRegistrationStore.fetchStreetTypeList(getMappedCountry(corpProfileCountry))
    }

    const goToBusinessOwners = () => {
      router.push({ name: 'businessOwners' })
    }

    // redirect to business owners main page if no action is supplied (when page refresh)
    if (!props.action) {
      goToBusinessOwners()
    }

    /* FORMS */
    // form factory
    const {
      setup: setupFields,
      // isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useAuthorizedPersonnelForm()

    // Set fields to ignore
    const fieldsToIgnore = []
    // Remove PEP fields
    if (!isPep) {
      fieldsToIgnore.push(...['countryOfBirth', 'nationality'])
    }
    // Remove fields for auth signatory flow
    if (isAuthSignatory) {
      fieldsToIgnore.push(...['firstName', 'lastName'])
    }
    // Remove ignored fields
    userFieldsForCountry = {
      ...userFieldsForCountry,
      fields: userFieldsForCountry.fields.filter(({ id }) => !fieldsToIgnore.includes(id)),
    }

    // Create field to render
    setupFields(userFieldsForCountry)

    /* ADDRESS - TODO: Create or update existing global address component */

    // Get selected address
    const formattedAddress = ref('')
    const isSelectedAddress = ref(false)
    const isAddressReady = ref(false)
    const addressErrorMessage = ref('')
    const selectedAddress = ref(null)
    const hasAddress = computed(
      () =>
        Boolean(props.authorizedPersonnel?.freeFormatAddress) ||
        Boolean(props.authorizedPersonnel?.fixedFormatAddress)
    )

    const onInput = (address) => {
      const trimmedAddress = address.trim()
      formattedAddress.value = trimmedAddress
    }
    const onSelect = (address) => {
      address = { ...address, county: address.provinceName }
      formattedAddress.value = useAddressFormat(address)
      // include county field in address search for populating one line address
      const [streetName, streetType] = extractStreetNameAndStreetType(address.street)
      address = {
        ...address,
        unit: address.subBuilding,
        streetnumber: address.buildingNumber,
        streetname: streetName,
        streettype: streetType,
        suburb: address.district,
        place: address.city,
        county: address.provinceName,
        state: address.provinceName || '',
        postalcode: address.postCode,
      }
      selectedAddress.value = address
      isSelectedAddress.value = true
      isAddressReady.value = true
    }
    const onAddressClear = () => {
      isManuallyClick.value = true
      isSelectedAddress.value = false
      isAddressReady.value = false
      // Set manually click field values
      if (hasAddress.value) {
        manuallyClickData.value = props.authorizedPersonnel
      } else if (
        Object.keys(selectedAddress.value) &&
        corpProfile.registrationAddress?.fixedFormat
      ) {
        const [streetName, streetType] = extractStreetNameAndStreetType(
          selectedAddress.value.street
        )
        selectedAddress.value = {
          unit: selectedAddress.value.subBuilding,
          streetnumber: selectedAddress.value.buildingNumber,
          streetname: streetName,
          streettype: streetType,
          city: selectedAddress.value.city,
          suburb: selectedAddress.value.district,
          state: selectedAddress.value.state,
          postalcode: selectedAddress.value.postCode,
        }
        manuallyClickData.value = { fixedFormatAddress: selectedAddress.value }
      } else if (Object.keys(selectedAddress.value)) {
        manuallyClickData.value = { freeFormatAddress: selectedAddress.value }
      }
    }
    const onManuallyClickFieldsStatus = (value) => {
      isAddressReady.value = value
      formattedAddress.value = ''
    }
    const manuallyAddressData = (address) => {
      selectedAddress.value = address
      formattedAddress.value = useAddressFormat(address)
    }
    const onEnterManuallyClick = (status) => (isManuallyClick.value = status)
    const validateAddress = (address) => {
      const addressSearchLoadingRegex = /.+?\|.+?\|.+?\|.+?/
      if (addressSearchLoadingRegex.test(address)) {
        isAddressReady.value = false
        return
      }
      const regex = new RegExp(countryConfig.value.addressRegex)
      const isValid = regex.test(address)
      if (isValid) {
        addressErrorMessage.value = ''
      } else {
        addressErrorMessage.value = 'The address you entered is invalid. Please try again'
      }
    }

    watch(formattedAddress, (formattedAddress) => {
      validateAddress(formattedAddress)
    })

    /* VALIDATION */

    // Get emit to obtain document validation
    const documentsValidation = (e) => (isDocumentValid.value = e)

    // Determine button status depending on step value
    const isSubmitButtonDisabled = computed(() => {
      const informationValidation = Boolean(
        !hasFormFields.value ||
          isFormInvalid.value ||
          !isAddressReady.value ||
          addressErrorMessage.value
      )
      return step.value === 'documents' ? !isDocumentValid.value : informationValidation
    })

    /* SUBMISSION */
    const setPersistedValue = (value, target) => {
      formFields.value.find((field) => field.id === target).value = value
    }

    const saveBusinessOwner = async () => {
      let formData = {}

      loading.value = true
      // This is the function that saves the owner
      const getPersonnelType = getFieldValue('personneltype', formFields)

      // create formData to save to store
      let fieldIds = userFieldsForCountry.fields.map((field) => field.id)
      fieldIds.forEach((id) => {
        formData[id] = getFieldValue(id, formFields)
        // Handle personnel type value
        if (id === 'personneltype') {
          // Get option ids that without custom input flag
          let optionValues = userFieldsForCountry.fields
            .find((field) => field.id === id)
            .options.filter((option) => !option.allowTextBox)
            .map((option) => option.value)

          optionValues.forEach((value) => {
            // if custom input:
            // set role = customInputValue
            // set isUbo & isDirector = false
            if (typeof getPersonnelType === 'string') {
              formData['role'] = getPersonnelType
              formData[value] = false
            } else {
              // else
              // set isUbo & isDirector based on selected values
              formData[value] = getPersonnelType?.some((personnelType) => personnelType === value)
            }
          })
        }
      })

      formData['address'] = formattedAddress.value
      formData['docdetails'] = documentsData.value
      if (selectedAddress.value) {
        if (corpProfile.registrationAddress?.fixedFormat) {
          formData['fixedFormatAddress'] = selectedAddress.value
        } else {
          formData['freeFormatAddress'] = selectedAddress.value
        }
      }

      // If user select manually address set status
      if (isManuallyClick.value) {
        isSelectedAddress.value = true
      }

      // update required fields only if props.authorizedPersonnel exists
      if (props.authorizedPersonnel) {
        formData = { ...props.authorizedPersonnel, ...formData }
      }

      // Saves the form (Should Update here as well)
      if (isEditingPersonnel) {
        dashboardStore.setAuthorizedPersonnelByIndex({
          authorizedPersonnel: formData,
          index: props.index,
        })

        const editedFields = checkEditedFields(formData, props.authorizedPersonnel)
        if (editedFields.length > 0) {
          // Send Edited even
          await analyticsStore.track({
            event: 'Individual Edited',
            traits: {
              ownershipRole: getIndividualRoles(formData).join(', ').toLowerCase(),
              fieldEdited: editedFields.join(', ').toLowerCase(),
            },
          })
        }
      } else {
        dashboardStore.setAuthorizedPersonnel(formData)
      }

      // Send EKYC document uploaded Event
      if (isApac.value) {
        const formatDocumentSelected = (value) => {
          const mappedValues = { drivinglicence: 'Driver’s licence', passport: 'Passport' }
          return mappedValues[String(Object.keys(value))]
        }
        await analyticsStore.track({
          event: 'EKYC Document uploaded',
          traits: {
            documentSelected: formatDocumentSelected(documentsData.value),
            individualEmail: getFieldValue('email', formFields),
          },
        })
      }
      // Individual saved
      await analyticsStore.track({
        event: 'Individual Saved',
        traits: {
          ownershipRole: getIndividualRoles(formData).join(', ').toLowerCase(),
        },
      })
      // can we remove this PUT call? we're already saving it in dashboardbusinessowners
      if (isAuthSignatory && selectedAddress.value) {
        await dashboardStore.putCorpProfile()
      }
      loading.value = false
      goToBusinessOwners()
    }

    /* DOCUMENTS PAGE */
    const documentsData = ref({})
    const getDocumentsData = (e) => (documentsData.value = e)

    /* EDIT */

    // Set an object with fields that are modified by the user in order to send an amplitude event
    const checkEditedFields = (fields, oldFields) => {
      let editedFields = []
      const checkFieldValue = (valueA, valueB, fieldToAdd) => {
        valueA?.trim() !== valueB?.trim() ? editedFields.push(fieldToAdd) : null
      }

      // Check address
      checkFieldValue(fields.address, oldFields.address, 'address')
      // Check DOB
      checkFieldValue(fields.dob, oldFields.dob, 'dob')
      // Check first name
      checkFieldValue(fields.firstName, oldFields.firstName, 'firstName')
      // Check last name
      checkFieldValue(fields.lastName, oldFields.lastName, 'lastName')
      // Check owners
      checkFieldValue(fields.email, oldFields.email, 'email')
      // Check owners
      !(
        getIndividualRoles(fields).join(', ').toLowerCase() ===
        getIndividualRoles(oldFields).join(', ').toLowerCase()
      )
        ? editedFields.push('role')
        : null
      return editedFields
    }

    // Handle edit user flow
    if (props?.authorizedPersonnel) {
      const authorizedPersonnel = props?.authorizedPersonnel
      // set existing values to each field
      let fieldIds = userFieldsForCountry.fields.map((field) => field.id)
      fieldIds.forEach((id) => {
        let fieldValue = authorizedPersonnel[id]
        setPersistedValue(fieldValue, id)
      })

      // Set BVD address
      if (authorizedPersonnel.address && hasAddress.value) {
        // Has free format address return summary address screen
        formattedAddress.value = authorizedPersonnel.address
        isSelectedAddress.value = true
        isAddressReady.value = true
      } else {
        // Has full address without free format return field with search result
        formattedAddress.value = authorizedPersonnel.address
      }
    }

    /* INITIAL EVENT  */

    // Pending Action Started | Trigger when user hit authorized personnel page (create)
    if (isFirstLoadAuthSignatory.value) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Business Owners',
        },
      })
    }

    return {
      selectedContent,
      formFields,
      onSelect,
      onInput,
      onAddressClear,
      formattedAddress,
      isSelectedAddress,
      addressErrorMessage,
      corpProfileCountry,
      isSubmitButtonDisabled,
      saveBusinessOwner,
      goToBusinessOwners,
      isEditingPersonnel,
      isAuthSignatory,
      isCreatingWithData,
      getFullNameOfExistingPersonnel,
      selectedAddress,
      loading,
      isManuallyClick,
      onEnterManuallyClick,
      onManuallyClickFieldsStatus,
      isAddressReady,
      manuallyAddressData,
      hasAddress,
      manuallyClickData,
      heading,
      subHeading,
      headingStatus,
      contentFlow,
      submitButtonText,
      submitButtonClick,
      backButtonClick,
      step,
      isFirstLoadAuthSignatory,
      documentsValidation,
      getDocumentsData,
      mq,
    }
  },
}
</script>

<style lang="postcss" scoped>
.type-stp-title {
  @apply pb-6 text-xl;

  @screen sm {
    @apply text-2xl;
  }

  @screen md {
    @apply text-3xl;
  }
}

.type-stp-subheadline {
  @apply type-caption text-gray pb-10;
}

.back-button {
  @apply px-4;

  @screen sm {
    @apply px-0;
  }

  @screen md {
    @apply max-w-1/2xl ml-auto mr-auto;
  }
}

.confirm-checkbox {
  @apply flex justify-items-center items-center p-6 mb-6 border border-gray-light rounded-2xl shadow-ria-1;
}

.confirm-text {
  @apply type-caption text-gray-primary pl-4;
}

.card {
  /deep/ .card-content {
    @apply pt-0 !important;

    @screen sm {
      @apply pt-12 !important;
    }

    .card-content-block {
      @apply mb-0 !important;

      .business-owner-form {
        .first-name {
          @screen sm {
            @apply w-full inline-block align-top pr-3;
            max-width: 50%;
          }
        }

        .last-name {
          @screen sm {
            @apply w-full inline-block align-top pl-3;
            max-width: 50%;
          }
        }
      }
    }
  }
}

.business-owners-details-footer {
  @apply pt-0 z-50 !important;

  @screen sm {
    @apply pt-4 justify-end !important;
  }
}
</style>
