<template>
  <div>
    <!-- List -->
    <AppList class="business-owners-item">
      <!-- List link -->
      <AppListItem
        v-for="(eachPerson, index) in items"
        :key="index"
        class="business-owners-content"
      >
        <!-- Figure -->
        <template #figure>
          <AppFigure data-private class="figure" size="w-10 h-10">
            {{ getInitials(eachPerson.firstName + ' ' + eachPerson.lastName) }}
          </AppFigure>
        </template>

        <!-- Left -->
        <template #left>
          <!-- Title -->
          <AppListItemTitle
            data-private
            class="capitalize"
            v-text="capitalizeText(`${eachPerson.firstName} ${eachPerson.lastName}`)"
          ></AppListItemTitle>
          <!-- Roles -->
          <div class="list-item-roles">
            {{ getIndividualRolesStringCapitalized(eachPerson) }}
          </div>
          <!-- Status - Suggested individuals - Mobile devices -->
          <AppActionStatus
            v-if="mq.current === 'xs' && isIndividualPending(eachPerson)"
            status="individualPending"
          />
        </template>

        <!-- Content -->
        <template #action>
          <div class="business-owners-action">
            <!-- Status - Suggested individuals -->
            <AppActionStatus
              v-if="mq.current !== 'xs' && isIndividualPending(eachPerson)"
              status="individualPending"
            />
            <!-- Edit -->
            <button
              @click.prevent="editOwner('edit', eachPerson, index)"
              @keypress.prevent="editOwner('edit', eachPerson, index)"
            >
              <AppIcon name="Edit">
                <IconEdit />
              </AppIcon>
            </button>
            <!-- Remove -->
            <button
              class="remove-individual-button"
              :disabled="eachPerson.isSignatory"
              @click.prevent="onDeleteTriggered(index)"
              @keypress.prevent="onDeleteTriggered(index)"
            >
              <AppIcon name="TrashCan">
                <IconTrashCan />
              </AppIcon>
            </button>
          </div>
        </template>
      </AppListItem>
    </AppList>
    <!-- Go to add individual -->
    <div class="add-individual">
      <AppButton theme="text" class="add-individual-button" @click="createOwner('create')">
        <AppIcon class="add-individual-button-icon" name="Add">
          <IconAdd />
        </AppIcon>
        Add an individual
      </AppButton>
    </div>
  </div>
</template>

<script>
// Components
import AppList from '@en-ui/components/AppList/AppList.vue'
import AppButton from '@en-ui/components/AppButton/AppButton.vue'
import AppListItem from '@/components/AppListItem/AppListItem.vue'
import AppIcon from '@en-ui/components/AppIcon/AppIcon.vue'
import AppFigure from '@en-ui/components/AppFigure/AppFigure.vue'
import AppActionStatus from '@/components/AppActionStatus/AppActionStatus.vue'
import AppListItemTitle from '@/components/AppListItemTitle/AppListItemTitle.vue'

// Icons
import { IconEdit, IconTrashCan, IconAdd } from '@moneytransfer.ui/euronet-icons'

// Utils
import { getInitials, capitalizeText } from '@/utils/string'
import {
  getIndividualRoles,
  isIndividualPending,
  getIndividualRolesStringCapitalized,
} from '@/helpers/businessOwners'

// Composables
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'

export default {
  components: {
    AppButton,
    AppIcon,
    AppFigure,
    AppList,
    AppListItem,
    AppActionStatus,
    AppListItemTitle,
    IconAdd,
    IconEdit,
    IconTrashCan,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const mq = useMediaQuery()
    const onDeleteTriggered = (index) => emit('onDeleteTriggered', index)
    const editOwner = (action, person, index) => emit('editOwner', { action, person, index })
    const createOwner = (action) => emit('createOwner', action)

    return {
      capitalizeText,
      getInitials,
      getIndividualRoles,
      isIndividualPending,
      getIndividualRolesStringCapitalized,
      onDeleteTriggered,
      editOwner,
      createOwner,
      mq,
    }
  },
}
</script>

<style lang="postcss" scoped>
.list-item-roles {
  @apply text-gray-blue-200 type-caption;
}

.business-owners-action {
  @apply flex gap-4 text-text-gray-primary;
}

.list-item-title {
  @apply type-subtitle-bold font-semibold text-gray-blue-400;
}

.business-owners-content {
  @apply mb-0 border-transparent;
}

.add-individual {
  .add-individual-button {
    @apply pb-4 pt-4 text-left h-full !important;
    ::v-deep .button-inner {
      @apply type-caption-bold p-0 pr-2 rounded-2xl !important;
    }
    &:hover:not(:disabled) {
      @apply bg-transparent;
    }
    &-icon {
      @apply rounded-full h-auto w-auto p-2 mr-2;
      background-color: #f2f7fe;
    }
  }
}
.remove-individual-button:disabled {
  @apply text-orange-lighter;
}

::v-deep.figure {
  @apply rounded-full w-10 h-10;
}
</style>
